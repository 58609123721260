<script setup>
</script>

<template>
  <div class="grid-state-template">
    <Header />
    <el-main>
      <slot />
    </el-main>
  </div>
</template>
